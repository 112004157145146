import CustomBreadcrumbs from "../../components/minimal/custom-breadcrumbs";
import { fDate } from "../../utils/minimal/formatTime";
import { Stack } from "@mui/material";
import Label from "../../components/minimal/label";
import { useLocales } from "../../locales";
import useCopyToClipboard from "../../hooks/minimal/useCopyToClipboard";
import { useSnackbar } from "notistack";
import { useEventLink } from "../../hooks/useEventLink";

export const EventBreadcrumbs = ({ heading, event, action, sx = {} }) => {

    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();
    const { copy } = useCopyToClipboard();
    const eventUrl = useEventLink()
    // this domain + /scanner/ + event.id
    const scannerUrl = `${window.location.origin}/scanner/${event._id}`

    const handleCopy = (text) => {
        enqueueSnackbar(translate('dashboard.events.copied'));
        copy(text);
    };

    return (
        <Stack>
            <CustomBreadcrumbs
                action={action}
                sx={sx}
                heading={heading}
                headAction={
                    <Stack direction="row" gap="10px">
                        <Label onClick={() => handleCopy(eventUrl)} sx={{ py: 1, mt: 1, cursor: "pointer" }} color="info" variant="soft">
                            העתק לינק אירוע
                        </Label>
                        <Label onClick={() => handleCopy(scannerUrl)} sx={{ py: 1, mt: 1, cursor: "pointer" }} color="warning" variant="soft">
                            העתק לינק לסורק
                        </Label>
                    </Stack>
                }
                links={[
                    {
                        name: event.name,
                    },
                    {
                        name: fDate(event.start_date),
                    }
                ]}
            />
        </Stack>
    )
}