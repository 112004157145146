import { useEffect } from "react";
import { useAuthContext } from "../../../context-state/AuthContext";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader";
import { useMarketplaceContext } from "../../../context-state/MarketplaceContext";
import * as API from '../../../api/index.js'

// Set chosen sub account before continue to the subAccount paths.
export const SubAccountGuard = () => {

    const { allAccounts, setChosenAccount, account, user } = useAuthContext();
    const { marketplaceAccountsSellers } =  useMarketplaceContext()
    const navigate = useNavigate()
    const { marketplace, setMarketplaceAccountsSellers } = useMarketplaceContext()
    const { subId } = useParams()

    // This logic could be export to a Guard to wrap the whole dashboard
    const getMarketPlaceData = async () => {
        try {
            const marketplaceSellersData = await API.getAccountsOfMarketplace(marketplace._id)
            setMarketplaceAccountsSellers(marketplaceSellersData.data)
            return marketplaceSellersData.data
        } catch (e) {
            console.error(e)
        }
    }

    const setRelevantSubAccountAndFetchItIfNeeded = async () => {
        let chosenSubAccount = allAccounts.find(subAccount => subAccount._id === subId)
        if (!chosenSubAccount) {
            // if it's marketplace owner, we search through all the marketplace accounts (sellers)
            chosenSubAccount = marketplaceAccountsSellers.find(subAccount => subAccount._id === subId)
            if (!chosenSubAccount) {
                // Try to fetch them if the account is marketplace owner.
                if (user.isMarketPlaceOwner && marketplace.isSellersManager) {
                    const accountFromMarketplace = await getMarketPlaceData()
                    chosenSubAccount = accountFromMarketplace.find(subAccount => subAccount._id === subId)
                }
                if (!chosenSubAccount) {
                    navigate(PATH_DASHBOARD.sub.root)
                    return
                }
            }
        }
        setChosenAccount(chosenSubAccount)
    }

    useEffect(() => {
        setRelevantSubAccountAndFetchItIfNeeded()
    }, [])

    return account ? <Outlet /> : <LinearLoader />


}
