// routes
import { PATH_DASHBOARD, PATH_DASHBOARD_EVENT, PATH_DASHBOARD_SUB } from '../../../routes/paths';
// components
import Iconify from '../../../components/minimal/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    items: [
      {
        title: 'ראשי',
        path: PATH_DASHBOARD.main,
        icon: <Iconify icon="mage:dashboard-bar-notification" />,
        disableChildIndicator: true,
        activeEndsWithPath: true,
        isSellerRoute: true
      },
      {
        title: 'ניהול מערכת',
        disableActive: true,
        icon: <Iconify icon="carbon:id-management" />,
        path: '',
        children: [
          {
            title: 'הלקוחות שלי',
            isCrmRoute: true,
            disableChildIndicator: true,
            isSellerRoute: true,
            path: PATH_DASHBOARD.end_users,
            icon: <Iconify icon="fluent:person-money-24-filled" />,
          },
          {
            title: 'מנוי',
            disableChildIndicator: true,
            activeEndsWithPath: true,
            isAllowSeasonTicketRoute: true,
            isSellerRoute: true,
            isCrmRoute: true,
            path: PATH_DASHBOARD.season_ticket,
            icon: <Iconify icon="eva:award-outline" />,
          },
          {
            title: 'המוכרים שלי',
            isMarketPlaceOwnerRoute: true,
            isSellersManagerRoute: true,
            disableChildIndicator: true,
            activeEndsWithPath: true,
            path: PATH_DASHBOARD.sellers,
            icon: <Iconify icon="fluent:person-star-32-filled" />,
          },
          {
            title: 'ניהול מפות מושבים',
            isMarketPlaceOwnerRoute: true,
            disableChildIndicator: true,
            activeEndsWithPath: true,
            path: PATH_DASHBOARD.seats_chart_admin,
            icon: <Iconify icon="fluent:seat-20-regular" />,
          },
          {
            title: 'נתוני מכירות',
            isMarketPlaceOwnerRoute: true,
            disableChildIndicator: true,
            activeEndsWithPath: true,
            path: PATH_DASHBOARD.market_sale_stats,
            icon: <Iconify icon="mingcute:pig-money-line" />,
          },
          {
            title: 'שליחת אסמסים',
            isSellerRoute: true,
            disableChildIndicator: true,
            activeEndsWithPath: true,
            path: PATH_DASHBOARD.sms_campaign,
            icon: <Iconify icon="material-symbols:sms" />,
          },

        ]
      }
    ]
  },
  {
    subheader: 'ניהול הפקות',
    items: [
      {
        title: 'בחירת הפקה',
        disableChildIndicator: true,
        activeEndsWithPath: true,
        path: PATH_DASHBOARD.sub.root,
        icon: <Iconify icon="carbon:carbon" />,
      }
    ]
  },
  {
    dependOn: 'subId',
    items: [
      {
        title: '__sub__',
        disableActive: true,
        path: '',
        icon: <Iconify icon="carbon:carbon-for-ibm-product" />,
        children: [
          {
            title: 'events',
            disableChildIndicator: true,
            activeEndsWithPath: true,
            path: PATH_DASHBOARD_SUB.EVENT,
            icon: <Iconify icon="material-symbols:event" />,
            isAccountRelative: true
          },
          {
            title: 'משתמשי הפקה',
            disableChildIndicator: true,
            path: PATH_DASHBOARD_SUB.USER,
            icon: <Iconify icon="codicon:organization" />,
            isAccountRelative: true
          },
          {
            title: 'coupons',
            icon: <Iconify icon="ri:coupon-line" />,
            disableChildIndicator: true,
            disableActive: true,
            path: PATH_DASHBOARD_SUB.COUPONS,
            isAccountRelative: true
          },
        ]
      },
    ]
    //
  },
  // EVENT MANAGEMENT
  // ----------------------------------------------------------------------
  {
    dependOn: 'eventId', // display this links only when 'eventId' on the useParams returns object
    subheader: 'event_management',
    items: [
      {
        title: 'event_sale_stats',
        isRelative: true,
        path: PATH_DASHBOARD_EVENT.SELL_STATS,
        icon: <Iconify icon="mdi:dollar" />,
      },
      {
        title: 'edit_event',
        path: PATH_DASHBOARD_EVENT.EDIT,
        isRelative: true,
        icon: <Iconify icon="eva:edit-outline" />,
      },
      {
        title: 'event_settings',
        isRelative: true,
        path: PATH_DASHBOARD_EVENT.SETTINGS,
        icon: <Iconify icon="eva:settings-2-outline" />,
      },
      {
        title: 'coupons',
        path: PATH_DASHBOARD_EVENT.COUPONS,
        isRelative: true,
        icon: <Iconify icon="ri:coupon-line" />,
      },
      {
        title: 'season_tickets_discount',
        path: PATH_DASHBOARD_EVENT.SEASON_TICKETS_DISCOUNT,
        isRelative: true,
        icon: <Iconify icon="streamline:subscription-cashflow" />,
      },
      {
        title: 'event_orders',
        path: PATH_DASHBOARD_EVENT.ORDERS,
        isRelative: true,
        icon: <Iconify icon="eva:people-outline" />,
      },
      {
        title: 'event_links',
        path: PATH_DASHBOARD_EVENT.LINKS,
        isRelative: true,
        icon: <Iconify icon="eva:link-2-outline" />,
      },
      {
        title: 'seats_chart',
        path: PATH_DASHBOARD_EVENT.SEATS_CHART,
        isRelative: true,
        icon: <Iconify icon="material-symbols:event-seat-outline" />,
      },
    ],
  }
];

export default navConfig;
