import { useAuthContext } from "../../../context-state/AuthContext.jsx";
import { redirect, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMarketplaceContext } from "../../../context-state/MarketplaceContext.jsx";

export const ROLES_LABELS = {
    OWNER: 'owner',
    ADMIN: 'admin',
    EDITOR: 'editor',
    VIEWER: 'viewer',
    READER: 'reader',
    SCANNER: 'scanner',
    USER: 'user'
}

const RolesMap = {
    owner: { label: ROLES_LABELS.OWNER, weight: 100 },
    admin: { label: ROLES_LABELS.ADMIN, weight: 80 },
    editor: { label: ROLES_LABELS.EDITOR, weight: 40 },
    viewer: { label: ROLES_LABELS.VIEWER, weight: 30 }, // one of them irrelevant
    reader: { label: ROLES_LABELS.READER, weight: 30 }, // one of them irrelevant
    scanner: { label: ROLES_LABELS.SCANNER, weight: 20 },
    user: { label: ROLES_LABELS.USER, weight: 1 }
}


export const useRole = () => {

    const { account, user, getPrimaryAccount } = useAuthContext()
    const { marketplace } = useMarketplaceContext()

    const getUserRole = () => {

        const primaryAccount = getPrimaryAccount()

        if (user._id === primaryAccount?.owner) {
            return ROLES_LABELS.OWNER
        }

        if (user._id === account?.owner) {
            return ROLES_LABELS.OWNER
        }

        if (marketplace?.owners) {
            if (marketplace.owners.includes(user._id)) {
                return ROLES_LABELS.OWNER
            }
        }

        if (account?.collaborates) {
            const userCollaborateEntityForThisAccount = account.collaborates.find(collaborate => collaborate.user === user._id)
            if (userCollaborateEntityForThisAccount) {
                return userCollaborateEntityForThisAccount.role
            }
        }

        return ROLES_LABELS.USER
    }

    // The order of the roles check is the opposite of 'getUserRole' because the user might be the marketplace owner but i want to show him the targetAccount role he has.
    const getUserRoleForSpecificAccount = (targetAccount) => {
        const primaryAccount = getPrimaryAccount()

        if (targetAccount?.collaborates) {
            const userCollaborateEntityForThisAccount = targetAccount.collaborates.find(collaborate => collaborate.user === user._id)
            if (userCollaborateEntityForThisAccount) {
                return userCollaborateEntityForThisAccount.role
            }
        }

        if (user._id === primaryAccount?.owner) {
            return ROLES_LABELS.OWNER
        }

        if (user._id === targetAccount?.owner) {
            return ROLES_LABELS.OWNER
        }

        if (marketplace?.owners) {
            if (marketplace.owners.includes(user._id)) {
                return ROLES_LABELS.OWNER
            }
        }

        return ROLES_LABELS.USER
    }

    const userAllowToAccess = (minimumRoleAllowedAccess) => {

        const userRole = getUserRole()
        if (userRole === ROLES_LABELS.USER) {
            return false
        }

        const minWeightToAccess = RolesMap[minimumRoleAllowedAccess].weight
        const userRoleWeight = RolesMap[userRole].weight

        return userRoleWeight >= minWeightToAccess
    }


    return { getUserRole, userAllowToAccess, getUserRoleForSpecificAccount }
}


// Redirect user in case he is not allowed to access the page.
export const RoleBasedRouteGuard = ({ children, minimumRoleAllowedAccess }) => {
    const { userAllowToAccess } = useRole()
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const isAllow = userAllowToAccess(minimumRoleAllowedAccess)

    useEffect(() => {
        if (!isAllow) {
            navigate('/dashboard')
            enqueueSnackbar('למשתמש אין גישה לעמוד זה', { variant: 'error' });
        }
    }, [])

    if (isAllow) {
        return children
    }

    return null

}


// Display Component if user allow to see it.
export const RoleBasedComponentGuard = ({ children, minimumRoleAllowedAccess }) => {
    const { userAllowToAccess } = useRole()
    const isAllow = userAllowToAccess(minimumRoleAllowedAccess)
    return isAllow ? children : null
}

