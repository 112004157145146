import { makeAutoObservable } from "mobx";
import * as api from "../api";
import AccountStore from "./account.store";

class UserStore {
    user = null;
    accounts = {};
    initializationPromise = null;
    primaryAccount = null;
    
    constructor() {
        makeAutoObservable(this);
    }

    setUser(user) {
        this.user = user;
    }

    async init() {
        if (this.initializationPromise) {
            return this.initializationPromise;
        }
        this.initializationPromise = api.getAccountsByJwt(this.user.token);
        const { data: accounts } = await this.initializationPromise;
        accounts.forEach(account => {
            this.accounts[account._id] = new AccountStore(account);
            if(!account.isSubAccount) {
                this.primaryAccount = account;
            }
        })
    }
}

export default new UserStore();