import * as Sentry from "@sentry/react";


const isErrorObject = (obj) => obj && obj.stack && obj.message
const tryToSafelyStringify = (obj) => {
    try {
        return JSON.stringify(obj)
    } catch (e) {
        console.log(e);
    }
}

export const ErrorService = {
    handleError(e, hint) {
        try {
            // const errorToReport = e
            // Still need to think about it...
            // if (!isErrorObject(e)) {
            //     const stringifiedError = tryToSafelyStringify(e)
            //     if (stringifiedError) {
            //         errorToReport = new Error(stringifiedError)
            //     }
            //     // If we failed to stringify, report the raw object anyway to Sentry
            // }

            /* Report to Sentry */
            Sentry.captureException(e, hint)
        } catch (veryBadError) {
            console.log(veryBadError);
        }
    }
}