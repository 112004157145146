import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
import {
  Page404,
  HomePage,
  LoginPage,
  ResetPasswordPage,
  NewPasswordPage,
  AcceptInvitePage,
  EventPage,
  EventsPage,
  SubscriptionPage,
  NewEventPage,
  EditEventPage,
  TicketPage,
  SaleStatsPage,
  CouponsPage,
  SeasonTicketsDiscountPage,
  // LinksPage,
  OrdersPage,
  EventSettingsPage,
  CollaboratesPage,
  ProfileSettingsPage,
  SeatsChartPage,
  MySellersPage,
  SeatsChartAdminPage,
  SubAccountsPage,
  MarketSaleStatsPage,
  PartnerOnBoardingPage,
  SellerPublicPage,
  SupportPage,
  EndUsersPage,
  SingleEndUserPage, SeasonTicketPage,
  GeneralAppPage,
  PublicEventsPage,
  PublicEventsTablePage,
  EventScannerPage,
} from './elements';
import { DashboardProvider } from "../context-state/DashboardContext";
import { EventProvider } from "../context-state/EventContext";
import { SubscriptionProvider } from '../context-state/SubscriptionContext';
import { EventGuard } from "../pages/common/guards/EventGuard";
import { AllEventsGuard } from "../pages/common/guards/AllEventsGuard";
import { TicketProvider } from "../context-state/TicketContext";
import { SubAccountGuard } from "../pages/dashboard/sub-accounts/SubAccountGuard";
import { PermissionProvider } from "../context-state/PermissionContext";
import { CollaboratesGuard } from "../pages/dashboard/collaborates/CollaboratesGuard";
import { MarketplaceProvider } from "../context-state/MarketplaceContext";
import { MySellersGuard } from "../pages/dashboard/my-sellers/guards/MySellersGuard";
import { SeatsChartGuard } from "../pages/dashboard/seats-chart/common/guards/SeatsChartGuard";
import { MarketSaleStatsGuard } from "../pages/dashboard/admin/guards/MarketSaleStatsGuard";
import { SellerPageContextProvider } from "../context-state/SellerPageContext.jsx";
import { SellerPublicPageGuard } from "../pages/seller-public/guards/SellerPublicPageGuard.jsx";
import { RoleBasedRouteGuard, ROLES_LABELS } from "../pages/common/guards/RoleGuard.jsx";
import { EndUsersGuard } from "../pages/dashboard/end-users/guards/EndUsersGuard.jsx";
import { EndUsersProvider } from "../context-state/EndUsersContext.jsx";
import { SingleEndUserGuard } from "../pages/dashboard/end-users/guards/SingleEndUserGuard.jsx";
import LinksPage from "../pages/dashboard/links/LinksPage.jsx";
import { SeasonTicketProvider } from "../context-state/SeasonTicketContext.jsx";
import { SeasonTicketGuard } from "../pages/dashboard/season-ticket/guards/SeasonTicketGuard.jsx";
import { DashboardGuard } from "../pages/common/guards/DashboardGuard.jsx";
import { GeneralAppProvider } from "../context-state/GeneralAppContext.jsx";
import { SmsCampaignProvider } from "../context-state/SmsCampaignContext.jsx";
import { SmsCampaignGuard } from "../pages/dashboard/sms-campaig/guards/SmsCampignGuard.jsx";
import SmsCampaign from "../pages/dashboard/sms-campaig/SmsCampaign.jsx";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'new-password',
          element: (
            <GuestGuard>
              <NewPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'accept-invitation',
          element: <AcceptInvitePage />,
        },
        // partner
        {
          path: 'partner-onboarding',
          element: <PartnerOnBoardingPage />
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <GeneralAppProvider>
            <DashboardProvider>
              <EndUsersProvider>
                <PermissionProvider>
                  <SeasonTicketProvider>
                    <SmsCampaignProvider>
                      <DashboardLayout />
                    </SmsCampaignProvider>
                  </SeasonTicketProvider>
                </PermissionProvider>
              </EndUsersProvider>
            </DashboardProvider>
          </GeneralAppProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'main',
          element: <DashboardGuard><GeneralAppPage /></DashboardGuard>
        },
        {
          path: 'profile',
          element: <ProfileSettingsPage />
        },
        {
          path: 'my-sellers',
          element: <MySellersGuard><MySellersPage /></MySellersGuard>
        },
        {
          path: 'sms-campaign',
          element: <SmsCampaignGuard><SmsCampaign /></SmsCampaignGuard>
        },
        {
          path: 'seats-chart-admin',
          element: <SeatsChartGuard><SeatsChartAdminPage /></SeatsChartGuard>
        },
        {
          path: 'market-sale-stats',
          element: <MarketSaleStatsGuard><MarketSaleStatsPage /></MarketSaleStatsGuard>
        },
        {
          path: 'season-ticket',
          element: <SeasonTicketGuard><SeasonTicketPage /></SeasonTicketGuard>
        },
        {
          path: 'end-users',
          element: (
            <EndUsersGuard>
              <SeasonTicketGuard>
                <Outlet />
              </SeasonTicketGuard>
            </EndUsersGuard>
          ),
          children: [
            {
              index: true,
              element: <EndUsersPage />,
            },
            {
              path: ':endUserId',
              element: <SingleEndUserGuard><SingleEndUserPage /></SingleEndUserGuard>,
            }
          ],
        },
        {
          path: 'sub',
          children: [
            { element: <SubAccountsPage />, index: true },
            {
              path: ':subId',
              element: <SubAccountGuard />,
              children: [
                {
                  path: 'collaborate',
                  element: <RoleBasedRouteGuard minimumRoleAllowedAccess={ROLES_LABELS.ADMIN}><CollaboratesGuard><CollaboratesPage /></CollaboratesGuard></RoleBasedRouteGuard>
                },
                {
                  path: 'coupons',
                  element: <RoleBasedRouteGuard minimumRoleAllowedAccess={ROLES_LABELS.ADMIN}><CouponsPage /></RoleBasedRouteGuard>
                },
                {
                  path: 'event',
                  element: <AllEventsGuard />,
                  children: [
                    { element: <EventsPage />, index: true },
                    { path: 'new', element: <NewEventPage /> },
                    {
                      path: ':eventId',
                      // We wrap here with the EventProvider cause we need the checkout functionality of the event page inside the orders module (is it good practice? im not sure, but it works for now)
                      element: <EventProvider><RoleBasedRouteGuard minimumRoleAllowedAccess={ROLES_LABELS.READER}><EventGuard /></RoleBasedRouteGuard></EventProvider>,
                      children: [
                        // { element: <SingleEventDashboard />, index: true },
                        { path: 'edit', element: <EditEventPage /> },
                        { path: 'sell-stats', element: <SaleStatsPage /> },
                        { path: 'coupons', element: <CouponsPage /> },
                        { path: 'season-tickets-discount', element: <SeasonTicketsDiscountPage /> },
                        { path: 'links', element: <LinksPage /> },
                        { path: 'orders', element: <OrdersPage /> },
                        { path: 'settings', element: <EventSettingsPage /> },
                        { path: 'seats-chart', element: <SeatsChartGuard><SeatsChartPage /></SeatsChartGuard> },
                      ]
                    },

                  ]
                },
              ]
            }
          ]
        }
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'seller/*', element: <SellerPageContextProvider><SellerPublicPageGuard><SellerPublicPage /></SellerPublicPageGuard></SellerPageContextProvider> }
      ],
    },
    // Event Page
    {
      children: [
        { path: 'event/*', element: <EventProvider><EventPage /></EventProvider> },
      ]
    },
    // events page
    {
      children: [
        { path: 'events/:type/:id', element: <PublicEventsPage /> },
      ]
    },
    {
      children: [
        { path: 'events-table/:type/:id', element: <PublicEventsTablePage /> },
      ]
    },
    {
      children: [
        { path: 'scanner/:eventId', element: <EventScannerPage /> },
      ]
    },
    // Subscription Page
    {
      children: [
        { path: 'subscription/*', element: <SubscriptionProvider><SubscriptionPage /></SubscriptionProvider> },
      ]
    },
    // Ticket Page
    {
      children: [
        { path: 'ticket/*', element: <TicketProvider><TicketPage /></TicketProvider> },
      ]
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '404', element: <Page404 /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'support', element: <SupportPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
