import { makeAutoObservable, runInAction } from "mobx";
import * as api from "../api";

class AccountStore {
    _id = null;
    account = null;
    events = [];
    isLoading = true;
    allCoupons = {};
    fetchingCoupons = false;

    constructor(_id) {
        makeAutoObservable(this);
        this._id = _id;
    }

    get productionCoupons() {
        return Object.values(this.allCoupons).filter(coupon => !coupon.event);
    }

    getCoupon(couponId) {
        return this.allCoupons[couponId];
    }

    async deleteCoupon(couponId) {
        await api.deleteCoupon({ accountId: this._id, couponId });
        runInAction(() => delete this.allCoupons[couponId]);
    }

    async fetchProductionCoupons() {
        try {
            this.fetchingCoupons = true;
            const { data } = await api.getCouponsByAccount({ accountId: this._id });
            data.forEach(c => this.allCoupons[c._id] = c);
        } catch (e) {
            console.error(e);
        } finally {
            runInAction(() => this.fetchingCoupons = false);
        }
    }

    async fetchData() {
        this.isLoading = true;
        const promises = [
            api.getAccountSecured(this._id),
            api.getAccountEvents(this._id),
            this.fetchProductionCoupons()
        ];
        const [account, events] = await Promise.all(promises);
        this.events = events.data.map(event => event._id);
        this.account = account.data;
        runInAction(() => this.isLoading = false);
    }
}

export default AccountStore;