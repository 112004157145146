import { useMarketplaceContext } from "../../../../../context-state/MarketplaceContext";
import { LinearLoader } from "../../../../../components/linear-loader/LinearLoader";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { SeatsIoService } from "../../../../../services/seatsIoService";
import { useAuthContext } from "../../../../../context-state/AuthContext.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../../../routes/paths.jsx";

export const SeatsChartGuard = ({ children }) => {

    const { marketplace, marketplaceCharts, setMarketplaceCharts } =  useMarketplaceContext()
    const { account } = useAuthContext()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { eventId , subId } = useParams()

    const getMarketPlaceData = async () => {
        try {
            // const seatsWorkspaceSecret = account?.parent?.seatsWorkspaceSecret ? account.parent.seatsWorkspaceSecret || account.parent.seatsWorkspaceSecret : marketplace.seatsWorkspaceSecret
            // TODO - needs to check why this not exist on the mareke
            const seatsWorkspaceSecret =  marketplace.seatsWorkspaceSecret ?? account.parent?.seatsWorkspaceSecret
            if (!seatsWorkspaceSecret) {
                enqueueSnackbar("ישנה בעיה במפת המושבים", { variant: 'warning' })
                navigate(`/dashboard/sub/${subId}/event/${eventId}/sell-stats`)
                return
            }

            const charts = await SeatsIoService.getRelevantCharts(seatsWorkspaceSecret)
            setMarketplaceCharts(charts)

        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
            console.error(e);
        }
    }

    useEffect(() => {
        if (!marketplaceCharts) {
            getMarketPlaceData()
        }
    }, [])

    return (
        marketplaceCharts ? children : <LinearLoader />
    )
}
